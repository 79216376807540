const environment = process.env.NODE_ENV;

const apis = {
  BASE_LOCAL_URL: environment === "development" ? "http://localhost:3000" : "",
  BASE: environment === "development" ? "http://localhost:5000" : "",
  LOGIN: "https://compute7api.asynctech.in/api/v1/adminlogin/",
  REGISTER_USER: "https://compute7api.asynctech.in/api/v1/public/register",
  GET_ADMIN_DETAILS: "https://compute7api.asynctech.in/api/v1/admin/details",
  GET_DASHBOARD_COUNT:
    "https://compute7api.asynctech.in/api/v1/admin/getDashboardCount",
  GET_TEACHER_DETAILS:
    "https://compute7api.asynctech.in/api/v1/admin/getAllTeachers",
  REMOVE_USER: "https://compute7api.asynctech.in/api/v1/admin/removeUser",
  UNBLOCK_USER: "https://compute7api.asynctech.in/api/v1/admin/unblockUser",
  GET_STUDENT_DETAILS:
    "https://compute7api.asynctech.in/api/v1/admin/getAllStudent",
  GET_SUBJECT_DETAILS:
    "https://compute7api.asynctech.in/api/v1/admin/getAllSubjects",
  REMOVE_SUBJECT: "https://compute7api.asynctech.in/api/v1/admin/removeSubject",
  UNBLOCK_SUBJECT:
    "https://compute7api.asynctech.in/api/v1/admin/unblockSubject",
  ADD_TEACHER: "https://compute7api.asynctech.in/api/v1/admin/register",
  ADD_SUBJECT: "https://compute7api.asynctech.in/api/v1/admin/addSubject",
};

export default apis;
