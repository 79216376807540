import React from "react";
import Registerform from "../RegisterForm/registerform";

const StudentRegister = ()=> {
   return (
    <div>
        <div className="parallax">
          <Registerform />
        </div>
    </div>
  );
}

export default StudentRegister;